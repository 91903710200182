//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      title: '',
    }
  },

  created() {
    this.setTitle();
  },

  methods: {
    setTitle() {
      let title = 'エラーが発生しました。'
      if (this.error.statusCode === 403) {
        title = 'このページはご利用いただけません。'
      } else if (this.error.statusCode === 404) {
        title = 'ページが見つかりません。'
      }

      this.title = title
    }
  },
}
