// 外部サービスのみ利用のプランの場合は、403エラーを返す
// e.g HowMaのみプランの場合など
export default ({$accessor, error}) => {
  const user = $accessor.users.user

  if (user?.company?.payment?.external_service_only) {
    // 403 Forbidden
    error({ statusCode: 403 })
  }
}
