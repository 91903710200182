




























import Vue from 'vue'

export default Vue.extend({
  props: {
    small: {
      type: Boolean,
      default() {
        return false
      },
    },

    large: {
      type: Boolean,
      default() {
        return false
      },
    },

    xLarge: {
      type: Boolean,
      default() {
        return false
      },
    },

    block: {
      type: Boolean,
      default() {
        return false
      },
    },

    outlined: {
      type: Boolean,
      default() {
        return false
      },
    },

    depressed: {
      type: Boolean,
      default() {
        return false
      },
    },

    text: {
      type: Boolean,
      default() {
        return false
      },
    },

    textWithUnderline: {
      type: Boolean,
      default() {
        return false
      },
    },

    color: {
      type: String,
      default() {
        return 'primary'
      },
    },

    to: {
      type: String,
      default() {
        return ''
      },
    },

    href: {
      type: String,
      default() {
        return ''
      },
    },

    loading: {
      type: Boolean,
      default() {
        return false
      },
    },

    width: {
      type: [String, Number],
      default() {
        return undefined
      },
    },

    maxWidth: {
      type: [String, Number],
      default() {
        return undefined
      },
    },

    height: {
      type: [String, Number],
      default() {
        return undefined
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      },
    },
    target: {
      type: String,
      default() {
        return undefined
      },
    },
    elevation: {
      type: [String, Number],
      default() {
        return undefined
      },
    },
  },

  data() {
    return {}
  },

  computed: {
    listeners(): object {
      // v:onで指定された内容を優先
      return { ...{ click: this.handleClick }, ...this.$listeners }
    },

    isText(): boolean {
      return this.text || this.textWithUnderline
    },

    style() {
      let styles = {} as any

      if (this.small) {
        styles = {
          'font-weight': 700,
          'font-size': '14px',
          'line-height': '24px',
          'border-width': '1px',
        }
      } else if (this.large) {
        styles = {
          'font-weight': 700,
          'font-size': '18px',
          'line-height': '40px',
          height: '45px',
        }
      } else if (this.xLarge) {
        styles = {
          'font-weight': 700,
          'font-size': '24px',
          'line-height': '52px',
          height: '60px',
        }
      } else {
        styles = {
          'font-weight': 700,
          'font-size': '16px',
          'line-height': '36px',
        }
      }

      if (this.text) {
        styles['font-size'] = '14px'
      } else if (this.textWithUnderline) {
        styles['font-size'] = '14px'
        styles['text-decoration-line'] = 'underline'
      } else {
        styles['box-shadow'] = '0 4px 4px rgba(0, 0, 0, 0.25)'
      }

      if (this.outlined) {
        styles['background-color'] = '#ffffff'
      }

      if (this.depressed) {
        styles['box-shadow'] = '0'
      }

      return styles
    },
  },

  created() {},

  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
})
