//
//
//
//
//
//
//
//
//
//
//
//

import AppHeader from '~/components/V2/Common/Header/AppHeader'

export default {
  components: { AppHeader },

  data() {
    return {
      user: {},
    }
  },

  head: {
    title: 'AI査定プロ',
    titleTemplate: 'AI査定プロ | %s',
  },

  computed: {
    currentPath() {
      return this.$route.path
    },
  },

  mounted() {
    // 印刷ページではfont-size-rootを9pxとする。
    // このように指定すれば同等となる。
    // 参考: https://github.com/vuetifyjs/vuetify/search?q=%24font-size-root
    document.getElementsByTagName('html')[0].style.fontSize = '14px'
  },
}
