import {
  mutationTree,
  actionTree
} from 'typed-vuex'
import {
} from '~/models/schema'

export const state = () => ({})

export const getters = {
  transactionTypeItems: () => {
    return {
      rentStoryItems: [
        {
          text: '成約',
          value: 'contracted',
        },
        {
          text: '募集',
          value: 'traded',
        },
      ],
      storyItems: [
        {
          text: '成約',
          value: 'contracted',
        },
        {
          text: '売出',
          value: 'traded',
        },
      ]

    }
  },
}

export const mutations = mutationTree(state, {})

export const actions = actionTree({ state, getters, mutations }, {})
