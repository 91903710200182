// 下記を参考に作成。
// https://auth.nuxtjs.org/recipes/extend

import { Api } from '~/models/schema'

export default ({ $auth }, inject) => {
  const token = $auth.strategy.token.get()
  const axiosConfig = {
    headers: {
      Accept: 'application/json',
      Authorization: token,
    },
    baseURL: process.env.AUTH0_IDENTIFIER, // Used as fallback if no runtime config is provided
  }

  const api = new Api({ ...axiosConfig })

  // APIのエラーハンドリング
  // この部分で正常な場合(200系)とそれ以外の挙動を調整しています。
  // https://axios-http.com/docs/interceptors
  api.instance.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response
    },
    function (error) {
      // 下記の公式ドキュメントを参考に実装しました。
      // https://axios-http.com/docs/handling_errors
      if (error.response) {
        if (error.response.status === 401) {
          // APIで401が返却された場合はログアウトする。
          $auth.logout()
        }
      }
      return Promise.reject(error)
    }
  )

  inject('api', api)
}
