import { mutationTree, getterTree, actionTree } from 'typed-vuex'

export const state = () => ({})

export const getters = getterTree(state, {
  honorificSuffixItems: () => {
    return [
      { text: '様', value: 'sama' },
      { text: '御中', value: 'onchu' },
      { text: '', value: 'none' },
    ]
  },
})

export const mutations = mutationTree(state, {})

export const actions = actionTree({ state, getters, mutations }, {})
