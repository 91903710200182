import Vue from 'vue'

Vue.filter('toManenUnit', function (value) {
  return Math.floor(value / 10000).toLocaleString()
})

Vue.filter('toFixed', function (value, n) {
  if (value === 0) {
    return value.toFixed(n)
  }

  if (!value) {
    return ''
  }

  return value.toLocaleString('ja-JP', {
    minimumFractionDigits: n,
    maximumFractionDigits: n,
  })
})

Vue.filter('toOmitted', function (str, n) {
  return str.length < n ? str : str.slice(0, n) + '...'
})

Vue.filter('toHanKaku', function (str) {
  return str
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    .replace(/['－']/g, '-')
})

Vue.filter('toMinutesText', function (value) {
  return value ? `${value}分` : ' - 分'
})

Vue.filter('toUseDistrictText', function (value, ctx) {
  let result = ''
  if (!value) {
    return result
  }

  result = ctx.$t(`enums.useDistrictOmitted.${value.use_district}`)
  // 調整区域と判断された場合、用途地域は「調整」と表示する。
  if (value.area_classification === 'urbanization_control_area') {
    result = '調整'
  }
  return result
})

// Vue.filter('toPercentage', function (value, n) {
//   return (value * 100).toFixed(n)
// })

// Vue.filter('toDigitWithSeparator', function (value) {
//   return (Math.round(value/10)*10).toLocaleString()
// })

// Vue.filter('toDigitTruncated', function (value) {
//   return Math.round(value*100)/100
// })
