//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      user: {},
    }
  },

  head: {
    title: 'AI査定プロ',
    titleTemplate: 'AI査定プロ | %s',
  },

  computed: {},
}
