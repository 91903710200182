// https://auth0.github.io/auth0-spa-js/classes/Auth0Client.html
import { createAuth0Client } from '@auth0/auth0-spa-js'

/* eslint-disable no-empty-pattern */
export default ({}, inject) => {
  const domain = process.env.AUTH0_DOMAIN ?? ''
  const clientId = process.env.AUTH0_CLIENT_ID ?? ''

  inject(
    'createAuth0Client',
    async (redirectUrl = `${window.location.origin}/nsl/signed-in`) => {
      const authorizationParams = {
        redirect_uri: redirectUrl,
      }

      const auth0 = await createAuth0Client({
        domain,
        clientId,
        authorizationParams,
      })
      return auth0
    }
  )
}
