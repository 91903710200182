






















































import Vue from 'vue'
import AppButton from '~/components/V2/Common/Button/AppButton.vue'
import AppHeading5 from '~/components/V2/Common/Heading/AppHeading5.vue'
import { CustomName, Payment, Plan, User } from '~/models/schema'

export default Vue.extend({
  components: {
    AppButton,
    AppHeading5,
  },
  props: {
    isShownLogout: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  computed: {
    isShownReportsCount(): boolean {
      return (
        this.$route.path === '/' ||
        this.$route.path === '/reports' ||
        this.$route.path === '/reports/'
      )
    },

    signedIn(): boolean {
      return this.$auth.$state.loggedIn
    },

    pageTitle(): string {
      return this.$accessor.page.title
    },

    user(): User {
      return this.$store.state.users.user
    },

    payment(): Payment {
      return this.user?.company?.payment
    },

    plan(): Plan {
      return this.payment.plan
    },

    reportsCountThisMonth(): number {
      return this.payment.reports_count_this_month
    },

    remainingMonthlyReportsCount(): number {
      return this.payment.remaining_monthly_reports_count
    },

    isNsl(): boolean {
      return this.$accessor.users.user?.company.custom_name === CustomName.Nsl
    },

    enabledSpeedMode(): boolean {
      return (
        this.$accessor.users.user?.permission_setting?.enabled_speed_mode ??
        false
      )
    },

    companyName(): string {
      return this.user?.company.name || ''
    },

    departmentName(): string {
      return this.user?.main_office?.name || this.user?.company.department_name || ''
    },

    topPath(): string {
      if (this.payment?.external_service_only) {
        // 空文字の場合は現在表示しているページに遷移することになる。
        // 外部サービス用のプランの場合はトップページに遷移することができないため、空文字を返す制御としています。
        return ''
      }

      return '/'
    },
  },

  methods: {
    // Log the user out
    async logout() {
      await this.$auth.logout()
    },
  },
})
