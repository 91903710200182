import { mutationTree, getterTree, actionTree } from 'typed-vuex'

export const state = () => ({})

export const getters = getterTree(state, {
  constructionTypeItems: (state) => {
    return [
      { text: '木造', value: 'wood' },
      { text: '木・鉄骨造', value: 'wood_s' },
      { text: '木・鉄筋コンクリート造', value: 'wood_rc' },
      { text: '木・鉄骨鉄筋コンクリート造', value: 'wood_src' },
      { text: '軽量鉄骨', value: 'lgs' },
      { text: '重量鉄骨', value: 'wgs' },
      { text: 'RC', value: 'rc' },
      { text: 'SRC', value: 'src' },
      { text: 'コンクリートブロック', value: 'cb' },
      { text: 'その他', value: 'other' },
      { text: '不明', value: 'unknown' },
    ]
  },

  currentStateItems: (state) => {
    return [
      { text: '居住中', value: 'living' },
      { text: '空家', value: 'vacant' },
      { text: '賃貸中', value: 'rent' },
      { text: '未完成', value: 'under_construction' },
      { text: '不明', value: 'unknown' },
    ]
  },
})

export const mutations = mutationTree(state, {})

export const actions = actionTree({ state, getters, mutations }, {})
